
export const enUsTranslations = {
  common: {
    attendees: {
      confirmAttendees: 'Confirm attendees',
      whoWillBeAttending: 'Who will be attending the funeral with you?',
      allAttendeesWillReceive: 'All attendees will receive a confirmation and information about the funeral via email.',
      doesThisPersonRequireSpecialAssistance: 'Does this person require special assistance?',
      hearingAndOrVisualImpairments: 'Hearing and/or visual impairments',
      physicalLimitationsWheelchair: 'Physical limitations (wheelchair)',
      ageIssues: 'Age-related issues',
      inLovingMemoryOf: 'In loving memory of',
      ourFamilyWouldLikeToKnow: 'Our family would like to know if you will be attending the funeral:',
      present: 'Present',
      absent: 'Absent',
      yourAbsenceHasBeenRegistered: 'Your absence has been registered',
      yourPresenceHasBeenRegistered: 'Your presence has been registered',
      youCanAlsoAttendTheFuneralViaTheLivestream: 'You can also attend the funeral via the Livestream. You will receive a notification message one hour before the start; keep an eye on the app.',
      copyLiveStreamLink: 'Copy Livestream link',
      downloadTheAppAndShareYourMemoriesAbout: 'Download the app and share your memories about ',
      youCanDownloadTheAppAndShareYourMemoriesAbout: 'You can download the app and view more details',
      youCanCheckAllTheDetailForTheFuneralInTheFuneralPage: 'You can check all the detail for the funeral in the funeral page.',
    },
    general: {
      firstName: 'First name',
      lastName: 'Last name',
      email: 'E-mail',
      fieldRequired: 'This field is required',
      emailIsRequired: 'Email is required',
      invalidEmail: 'Invalid email',
      remove: 'Remove',
      new: 'New',
      confirm: 'Confirm',
      done: 'Done',
      oops: 'Oops!',
      somethingWentWrong: 'Something went wrong. Please check the link and try again.',
    },
    privacy: {
      error: 'An error occurred while loading the privacy policy.',
      termsAndConditions: 'Terms and conditions',
      lastUpdated: 'Last updated:',
      pleaseReadTheTermsAndConditionsBelow: 'Please read the terms and conditions below lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    }
  },
}

