import { useId } from "react";
import { useTranslation } from "react-i18next";

type SwitchProps = {
  label: string;
  initialValue?: boolean;
  onChange?: () => void;
  disabled?: boolean;
};

export const Switch: React.FC<SwitchProps> = ({
  label,
  initialValue = false,
  onChange,
  disabled = false,
}: SwitchProps) => {
  const inputId = useId();
  const { t } = useTranslation();

  return (
    <div className="flex flex-row justify-between">
      <label htmlFor={inputId} className="mr-2 text-mainGrey font-400 text-base">
        {t(label)}
      </label>

      <div
        className={`
            relative inline-flex items-center cursor-pointer 
            ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
          `
        }
        onClick={() => !disabled && onChange && onChange()}
      >
        <input
          id={inputId}
          type="checkbox"
          className="hidden"
          role="switch"
          checked={initialValue}
          onChange={onChange}
          disabled={disabled}
        />
        <div
          className={`w-12 h-6 rounded-full transition-colors ${!initialValue ? 'bg-gray' : 'bg-success'
            }`}
        >
          <div
            className={`
                absolute w-6 h-6 bg-white rounded-full shadow-md transform transition-transform duration-200 ease-in-out 
                ${initialValue ? 'translate-x-6' : 'translate-x-0'
              }`
            }
          />
        </div>
      </div>
    </div>
  );
};
