import { FC } from "react";
import { useTranslation } from "react-i18next";

type TextButtonProps = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
};

export const TextButton: FC<TextButtonProps> = ({ text, onClick, disabled = false }) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`text-sm text-primary font-600 ${disabled ? 'text-lightGrey' : 'text-primary'}`}
    >
      {t(text)}
    </button>
  );
};
