import { useCallback, useState } from "react";
import { IFuneral, IMourningCard, IStar } from "../services/api/mourning-card/interfaces";
import { mourningCardApi } from "../services/api/mourning-card/mourningCardApi";

export const useFetchMourningCard = (funeralUuid: string) => {
  const [mourningCard, setMourningCard] = useState<IMourningCard | null>(null);
  const [star, setStar] = useState<IStar | null>(null);
  const [funeral, setFuneral] = useState<IFuneral | null>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const fetchMourningCard = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await mourningCardApi.getMourningCard(funeralUuid);

      setMourningCard(response.mourninCard);
      setStar(response.star);
      setFuneral(response.funeral);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [funeralUuid]);

  return {
    mourningCard,
    star,
    funeral,
    mourningCardLoading: loading,
    mourningCardError: error,
    fetchMourningCard,
  };
};
