import axios from "axios";
import { useCallback, useState } from "react";

export type Privacy = {
  id: string;
  version: string;
  content: string;
  dateTime: Date;
}

type RemotePrivacyModel = {
  id: string;
  version: string;
  content: string;
  created_at: string;
}

export const privacyApi = {
  async loadPrivacy(language: string): Promise<Privacy> {
    const response = await axios.get<RemotePrivacyModel>(
      `${process.env.REACT_APP_BASE_API_URL}terms/`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Language': language,
        },
      }
    );

    return {
      id: response.data.id,
      version: response.data.version,
      content: response.data.content,
      dateTime: new Date(response.data.created_at),
    }
  }
};


export const useLoadPrivacy = (language: string) => {
  const [privacy, setPrivacy] = useState<Privacy | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);

  const loadPrivacy = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await privacyApi.loadPrivacy(language);

      setPrivacy(response);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [language]);

  return {
    privacy,
    loadPrivacy,
    loadPrivacyLoading: loading,
    loadPrivacyError: error,
  };
}