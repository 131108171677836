import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Translator from "../../components/translator";
import DownloadApp from "../mourning-card/DeclineAttendancePage";
import { useLocation } from "react-router-dom";
import { GenericError } from "../../components/GenericError";

export const DoneAttendancePage = () => {
  const location = useLocation();

  if (!location.state) {
    return <GenericError />;
  }

  const { starName, liveStream } = location.state as {
    starName: string, liveStream: string
  };

  return (
    <div className="max-h-screen min-h-screen bg-white flex justify-center items-center p-5">
      <div className="w-full min-h-full max-h-full max-w-lg">
        <div className="flex min-h-full max-h-full flex-col">
          <main
            className="flex flex-col items-center py-16"
          >
            <div
              className="w-16 h-16 bg-white text-success rounded-full flex items-center justify-center border border-success cursor-pointer"
            >
              <FontAwesomeIcon
                icon={["fal", "check"]}
                size="xl"
              />
            </div>

            <div className="py-14 flex flex-col items-center">
              <p className="text-xl font-500 text-darkGray2">
                <Translator path="attendees.yourPresenceHasBeenRegistered" />
              </p>

              <p className="text-base text-mainGrey mt-4 font-400 text-center leading-6">
                <Translator path="attendees.youCanCheckAllTheDetailForTheFuneralInTheFuneralPage" />
              </p>
            </div>

            <DownloadApp starName={starName} />

            {
              liveStream &&
              <div
                className="flex items-center justify-center p-6 cursor-pointer"
                onClick={() => navigator.clipboard.writeText(liveStream)}
              >
                <p className="text-base font-500 text-primary">
                  <Translator path="attendees.copyLiveStreamLink" />
                </p>
              </div>
            }
          </main>
        </div>
      </div>
    </div>
  );
}
