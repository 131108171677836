import { CSSProperties } from "react";
import {
  IMourningCardPageSectionPhotoModel,
  IMourningCardTextStyle,
  IMourningCardTextStyleAligment,
  IMourningCardTextStyleFontWeight,
  MourningCardPageSectionPhotoStyleOrientationEnum,
} from "../../services/api/mourning-card/interfaces";

export const getTailwindFontWeight = (
  fontWeight: IMourningCardTextStyleFontWeight
) => {
  const splitedFontWeight = fontWeight.split(".");
  if (splitedFontWeight.length < 2) {
    return "";
  }
  const fontW = splitedFontWeight[1];

  if (!fontW.includes("w")) {
    return "";
  }
  const fontWNumber = fontW.split("w")[1];
  return `font-${fontWNumber}`;
};

export const getTailwindAlignment = (
  alignment: IMourningCardTextStyleAligment
) => {
  return `text-${alignment.split(".")[1]}`;
};

/*

final aspectRatioX =
            imageSection[index].orientation == OrientationType.standard
                ? 164
                : 125;
        final aspectRatioY =
            imageSection[index].orientation == OrientationType.standard
                ? 125
                : 164;


                */
export const getImageStyleProps = (
  data: IMourningCardPageSectionPhotoModel
) => {
  const styleProps = {
    border: data.imageStyle.border ? "solid 4px white" : "",
    orientation: data.imageStyle.orientation,
    rotation: data.imageStyle.rotation,
    aspectRatio:
      data.imageStyle.orientation ===
        MourningCardPageSectionPhotoStyleOrientationEnum.landscape
        ? "164 / 125"
        : "125 / 164",
    boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.16)",
  };

  return styleProps;
};

export const getTailwindFontFamily = (fontFamily: string) => {
  switch (fontFamily) {
    case "SFPro":
      return "font-sfPro";
    case "BeauRivage_regular":
      return "font-beauRivage";
    case "Metal_regular":
      return "font-metal";
    case "Cinzel_regular":
      return "font-cinzel";
    case "IndieFlower_regular":
      return "font-indieFlower";
    case "AmaticSC_regular":
      return "font-amaticSc";
    case "Kristi_regular":
      return "font-kristi";
    case "AbhayaLibre_regular":
      return "font-abhayaLibre";
    case "BebasNeue_regular":
      return "font-bebasNeue";
    case "Italiana_regular":
      return "font-italiana";
    default:
      return "font-sfPro";
  }
};

export const getTailwindClassesFromTextStyle = (
  data: IMourningCardTextStyle
) => {
  const parts = [];

  if (data.textStyle) {
    parts.push(getTailwindFontFamily(data.textStyle.fontFamily));
    parts.push(getTailwindFontWeight(data.textStyle.fontWeight));
  }

  return parts.join(" ");
};

export const getStylePropsFromTextStyle = (data: IMourningCardTextStyle): CSSProperties => {
  const textAlignments: CSSProperties['textAlign'][] = ['left', 'right', 'center', 'justify'];
  let textAlign: CSSProperties['textAlign'] = (data.alignment ? data.alignment.split(".")[1] : "center") as CSSProperties['textAlign'];

  const index = textAlignments.indexOf(textAlign);

  const styleProps: CSSProperties = {
    color: data.textStyle?.color,
    textAlign: textAlignments[index],
    fontSize: `${data.textStyle?.fontSize}px`,
  };

  return styleProps;
};
