import { createBrowserRouter } from "react-router-dom";
import { HomePage } from "./ui/pages/home/HomePage";
import { AttendancePage } from "./ui/pages/attendance/AttendancePage";
import { MourningCardPage } from "./ui/pages/mourning-card/MourningCardPage";
import { DeclineAttendancePage } from "./ui/pages/mourning-card/DeclineAttendancePage";
import { DoneAttendancePage } from "./ui/pages/attendance/DoneAttendancePage";
import { PrivacyPage } from "./ui/pages/privacy/PrivacyPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/:funeralUuid/attendance",
    element: <AttendancePage />,
  },
  {
    path: "/:funeralUuid/invite",
    element: <MourningCardPage />,
  },
  {
    path: "/stars/:starUuid/funerals/:funeralUuid/invite",
    element: <MourningCardPage />,
  },
  {
    path: "/invite/decline",
    element: <DeclineAttendancePage />,
  },
  {
    path: "/invite/done",
    element: <DoneAttendancePage />,
  },
  {
    path: '/privacy',
    element: <PrivacyPage />,
  },
  {
    path: "*",
    element: <HomePage />,
  },
]);
