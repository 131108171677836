import { useTranslation } from "react-i18next";

type FilledButtonProps = {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const FilledButton: React.FC<FilledButtonProps> = ({
  label,
  onClick,
  disabled = false,
}: FilledButtonProps) => {
  const { t } = useTranslation();

  return (
    <button
      className={`
        w-full h-12 bg-primary text-white font-500 text-lg rounded-md 
        flex justify-center items-center cursor-pointer transition-colors 
        px-6
        focus:outline-none lg:hover:bg-darkGold
        ${disabled ? 'opacity-50 cursor-not-allowed hover:bg-primary' : ''}
      `}
      onClick={!disabled ? onClick : undefined}
    >
      {t(label)}
    </button>
  );
};
