import {
  IMourningCardDates,
  IMourningCardPageModel,
  IMourningCardTextStyle,
  IMourningCardTextStyleAligment,
  IMourningCardTextStyleFontWeight,
  IMourningCardTextStyleTextStyle,
  IMourningCardPageSectionModel,
  IMourningCardPageSectionPhotoModel,
  IMourningCardPageSectionPhotoStyleModel,
  MourningCardPageSectionPhotoStyleOrientationEnum,
  MourningCardPageSectionPhotoStyleRotationEnum,
  MourningCardPageSectionTypeEnum,
  RemoteIviteModel,
} from "./interfaces";

type RemoteIvitResponse = {
  funeral: {
    is_public: boolean;
    type: string;
    uuid: string;
    livestream_url: string | null;
  },
  star: {
    uuid: string;
    first_name: string;
    date_of_birth: string;
    date_of_death: string;
    last_name: string;
    photo: string;
  },
  uuid: string;
  dates: {
    order: number;
    birthDate: string;
    deathDate: string;
    textStyle: {
      alignment: string;
      iconColor: string | null;
      textStyle: {
        color: string;
        height: number;
        fontSize: number;
        fontFamily: string;
        fontWeight: string;
      };
    };
    hideSection: boolean;
  };
  avatar: string;
  hide_avatar: boolean;
  background_image: string;
  background_color: string;
  pages: {
    page_number: number;
    sections: {
      uuid: string;
      order: number;
      type: string;
      mourning_card: string;
      content: string;
      text_style: {
        alignment: string;
        iconColor: string | null;
        textStyle: {
          color: string | null;
          height: number | null;
          fontSize: number | null;
          fontFamily: string | null;
          fontWeight: string | null;
        };
      };
      hide_section: boolean;
      photos: {
        image_style: {
          border: boolean;
          rotation: string;
          orientation: string;
        };
        file_path: string;
      }[];
    }[];
  }[];
};

type SampleBackendJsonDates = RemoteIvitResponse["dates"];
type SampleBackendJsonTextStyle = {
  alignment: string;
  iconColor: string | null;
  textStyle: {
    color: string | null;
    height: number | null;
    fontSize: number | null;
    fontFamily: string | null;
    fontWeight: string | null;
  };
};
type SampleBackendJsonTextStyleTextStyle =
  SampleBackendJsonTextStyle["textStyle"];
type SampleBackendJsonPages = RemoteIvitResponse["pages"];
type SampleBackendJsonSections = SampleBackendJsonPages[0]["sections"];
type SampleBackendJsonSection = SampleBackendJsonSections[0];
type SampleBackendJsonPhotos = SampleBackendJsonSection["photos"];
type SampleBackendJsonPhoto = SampleBackendJsonPhotos[0];
type SampleBackendJsonPage = SampleBackendJsonPages[0];
type SampleBackendJsonPageSection = SampleBackendJsonPage["sections"][0];

export const MourningCardConverter = {
  fromBackend: (data: RemoteIvitResponse): RemoteIviteModel => {
    const response: RemoteIviteModel = {
      mourninCard: {
        uuid: data.uuid,
        dates: MourningCardDatesConverter.fromBackend(data.dates),
        avatar: data.avatar,
        hideAvatar: data.hide_avatar,
        backgroundImage: data.background_image,
        backgroundColor: data.background_color,
        pages: data.pages.map((page) =>
          MourningCardPageConverter.fromBackend(page)
        ),
      },
      funeral: {
        isPublic: data.funeral.is_public,
        type: data.funeral.type,
        uuid: data.funeral.uuid,
        liveStream: data.funeral.livestream_url,
      },
      star: {
        uuid: data.star.uuid,
        firstName: data.star.first_name,
        dateOfBirth: new Date(data.star.date_of_birth),
        dateOfDeath: new Date(data.star.date_of_death),
        lastName: data.star.last_name,
        photo: data.star.photo,
      }
    };

    return response;
  },
};

export const MourningCardDatesConverter = {
  fromBackend: (data: SampleBackendJsonDates): IMourningCardDates => {
    return {
      order: data.order,
      birthDate: new Date(data.birthDate),
      deathDate: new Date(data.deathDate),
      textStyle: MourningCardTextStyleConverter.fromBackend(data.textStyle),
      hideSection: data.hideSection,
    };
  },
};

export const MourningCardTextStyleConverter = {
  fromBackend: (data: SampleBackendJsonTextStyle): IMourningCardTextStyle => {
    return {
      iconColor: data.iconColor,
      textStyle: MourningCardTextStyleTextStyleConverter.fromBackend(
        data.textStyle
      ),
      alignment: data.alignment as IMourningCardTextStyleAligment,
    };
  },
};

export const MourningCardTextStyleTextStyleConverter = {
  fromBackend: (
    data: SampleBackendJsonTextStyleTextStyle
  ): IMourningCardTextStyleTextStyle => {
    return {
      color: data.color || "#000000",
      fontSize: data.fontSize || 12,
      fontFamily: data.fontFamily || "SFPro",
      fontWeight: data.fontWeight as IMourningCardTextStyleFontWeight,
      height: data.height || 1,
    };
  },
};

export const MourningCardPageConverter = {
  fromBackend: (data: SampleBackendJsonPage): IMourningCardPageModel => {
    return {
      pageNumber: data.page_number,
      sections: data.sections.map((section) =>
        MourningCardPageSectionConverter.fromBackend(section)
      ),
    };
  },
};

export const MourningCardPageSectionConverter = {
  fromBackend: (
    data: SampleBackendJsonPageSection
  ): IMourningCardPageSectionModel => {
    return {
      uuid: data.uuid,
      order: data.order,
      type: data.type as MourningCardPageSectionTypeEnum,
      mourningCard: data.mourning_card,
      content: data.content,
      textStyle: MourningCardTextStyleConverter.fromBackend(data.text_style),
      hideSection: data.hide_section,
      photos: data.photos.map((photo) =>
        MourningCardPageSectionPhotoConverter.fromBackend(photo)
      ),
    };
  },
};

export const MourningCardPageSectionPhotoConverter = {
  fromBackend: (
    data: SampleBackendJsonPhoto
  ): IMourningCardPageSectionPhotoModel => {
    return {
      mourningCardImage: data.file_path,
      imageStyle: MourningCardPageSectionPhotoStyleModelConverter.fromBackend(
        data.image_style
      ),
    };
  },
};

export const MourningCardPageSectionPhotoStyleModelConverter = {
  fromBackend: (
    data: SampleBackendJsonPhoto["image_style"]
  ): IMourningCardPageSectionPhotoStyleModel => {
    return {
      border: data.border,
      orientation:
        data.orientation as MourningCardPageSectionPhotoStyleOrientationEnum,
      rotation: data.rotation as MourningCardPageSectionPhotoStyleRotationEnum,
    };
  },
};
