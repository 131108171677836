import axios from "axios";
import { MourningCardConverter } from "./helpers";
import { RemoteIviteModel } from "./interfaces";

export const sampleBackendJson = {
  uuid: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
  dates: {
    order: 3,
    birthDate: "1852-09-12T00:00:00.000",
    deathDate: "2024-09-12T00:00:00.000",
    textStyle: {
      alignment: "TextAlign.center",
      iconColor: "#00ff00",
      textStyle: {
        color: "#5E5E5E",
        height: 1.5,
        fontSize: 14.0,
        fontFamily: "SFPro",
        fontWeight: "FontWeight.w400",
      },
    },
    hideSection: false,
  },
  avatar: "https://i.pravatar.cc/300",
  hide_avatar: false,
  background_image: "https://picsum.photos/1080/1920",
  background_color: "#DCDCDC",
  pages: [
    {
      page_number: 1,
      sections: [
        {
          uuid: "1db9dc99-9f3b-4f18-82ef-91463bb09086",
          order: 2,
          type: "text",
          mourning_card: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
          content: "John Doe",
          text_style: {
            alignment: "TextAlign.center",
            iconColor: null,
            textStyle: {
              color: "#5E5E5E",
              height: 1.5,
              fontSize: 20.0,
              fontFamily: "SFPro",
              fontWeight: "FontWeight.w600",
            },
          },
          hide_section: false,
          photos: [],
        },
        {
          uuid: "33d2b719-4f88-47a6-a6ab-60b33a9c583c",
          order: 4,
          type: "text",
          mourning_card: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
          content:
            "The most beautiful thing you can leave behind are traces of love.\nWe let you go, deeply sad, but forever connected.",
          text_style: {
            alignment: "TextAlign.center",
            iconColor: null,
            textStyle: {
              color: "#5E5E5E",
              height: 1.5,
              fontSize: 16.0,
              fontFamily: "AbhayaLibre",
              fontWeight: "FontWeight.w400",
            },
          },
          hide_section: false,
          photos: [],
        },
      ],
    },
    {
      page_number: 2,
      sections: [
        {
          uuid: "a59849cf-9817-42b1-9c63-ff9cfe1dcd88",
          order: 1,
          type: "text",
          mourning_card: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
          content: "John Doe",
          text_style: {
            alignment: "TextAlign.center",
            iconColor: null,
            textStyle: {
              color: "#5E5E5E",
              height: 1.5,
              fontSize: 20.0,
              fontFamily: "SFPro",
              fontWeight: "FontWeight.w600",
            },
          },
          hide_section: false,
          photos: [],
        },
        {
          uuid: "70ca8bf6-d4ac-4f6b-948d-d142c151b30b",
          order: 2,
          type: "image",
          mourning_card: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
          content: "",
          text_style: {
            alignment: "TextAlign.center",
            iconColor: null,
            textStyle: {
              color: null,
              height: null,
              fontSize: null,
              fontFamily: "null",
              fontWeight: "null",
            },
          },
          hide_section: false,
          photos: [
            {
              image_style: {
                border: false,
                rotation: "tilted",
                orientation: "landscape",
              },
              file_path: "https://picsum.photos/250/350",
            },
            {
              image_style: {
                border: false,
                rotation: "tilted",
                orientation: "landscape",
              },
              file_path: "https://picsum.photos/200/300",
            },
          ],
        },
        {
          uuid: "30f7521e-a5fd-4b5b-b5c0-18821cdec439",
          order: 3,
          type: "text",
          mourning_card: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
          content:
            "We will be giving our farewell on Sunday, September 16, 2024 at Van Gogh Museum\nMuseumplein, 6\n1071dj, Amsterdam, Noord-Holland, Netherlands. ",
          text_style: {
            alignment: "TextAlign.center",
            iconColor: null,
            textStyle: {
              color: "#FF00FF",
              height: 1.5,
              fontSize: 16.0,
              fontFamily: "SFPro",
              fontWeight: "FontWeight.w400",
            },
          },
          hide_section: false,
          photos: [],
        },
        {
          uuid: "1d6ef94b-bf5b-4920-86fd-5f97d79d9e94",
          order: 4,
          type: "text",
          mourning_card: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
          content:
            "We bring John Doe to his final resting place this Tuesday, September 18, 2024 and 10:15 AM at Van Gogh Museum\nMuseumplein, 6\n1071dj, Amsterdam, Noord-Holland, Netherlands.",
          text_style: {
            alignment: "TextAlign.center",
            iconColor: null,
            textStyle: {
              color: "#5E5E5E",
              height: 1.5,
              fontSize: 16.0,
              fontFamily: "SFPro",
              fontWeight: "FontWeight.w400",
            },
          },
          hide_section: false,
          photos: [],
        },
        {
          uuid: "ec5a1651-4428-49fb-a52f-ab1018700eef",
          order: 5,
          type: "text",
          mourning_card: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
          content:
            "A private gathering will be held after the funeral, at <Address>",
          text_style: {
            alignment: "TextAlign.center",
            iconColor: null,
            textStyle: {
              color: "#5E5E5E",
              height: 1.5,
              fontSize: 16.0,
              fontFamily: "SFPro",
              fontWeight: "FontWeight.w400",
            },
          },
          hide_section: true,
          photos: [],
        },
      ],
    },
    {
      page_number: 3,
      sections: [
        {
          uuid: "6cbe92f8-5134-44e6-914e-b1b799e39d1d",
          order: 1,
          type: "text",
          mourning_card: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
          content:
            "Completely unexpectedly, we had to say goodbye to our <Relation>. Shocked and bewildered, we are left behind.",
          text_style: {
            alignment: "TextAlign.center",
            iconColor: null,
            textStyle: {
              color: "#5E5E5E",
              height: 1.5,
              fontSize: 16.0,
              fontFamily: "SFPro",
              fontWeight: "FontWeight.w400",
            },
          },
          hide_section: false,
          photos: [],
        },
        {
          uuid: "a441b257-5216-44c5-9894-d1d1682bbbb6",
          order: 2,
          type: "image",
          mourning_card: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
          content: "",
          text_style: {
            alignment: "TextAlign.center",
            iconColor: null,
            textStyle: {
              color: null,
              height: null,
              fontSize: null,
              fontFamily: "null",
              fontWeight: "null",
            },
          },
          hide_section: false,
          photos: [
            {
              image_style: {
                border: true,
                rotation: "straight",
                orientation: "standard",
              },
              file_path: "https://picsum.photos/456/789",
            },
          ],
        },
        {
          uuid: "b774b253-53a8-4725-a5bf-131980a5c132",
          order: 3,
          type: "text",
          mourning_card: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
          content: "Forever yours!\nLorem ipsum Dolor sit amet",
          text_style: {
            alignment: "TextAlign.center",
            iconColor: null,
            textStyle: {
              color: "#5E5E5E",
              height: 1.5,
              fontSize: 14.0,
              fontFamily: "SFPro",
              fontWeight: "FontWeight.w400",
            },
          },
          hide_section: false,
          photos: [],
        },
        {
          uuid: "2bb9da6b-5576-4cfa-ac8f-92a9c87f25e1",
          order: 4,
          type: "text",
          mourning_card: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
          content: "Correspondence address:",
          text_style: {
            alignment: "TextAlign.center",
            iconColor: null,
            textStyle: {
              color: "#5E5E5E",
              height: 1.0,
              fontSize: 14.0,
              fontFamily: "SFPro",
              fontWeight: "FontWeight.w700",
            },
          },
          hide_section: true,
          photos: [],
        },
        {
          uuid: "efe3bfbc-727b-4ac4-9184-30121474d771",
          order: 5,
          type: "text",
          mourning_card: "4479d0e0-f464-4fc5-b579-d3edd0a3ffb5",
          content: "",
          text_style: {
            alignment: "TextAlign.center",
            iconColor: null,
            textStyle: {
              color: "#5E5E5E",
              height: 1.5,
              fontSize: 14.0,
              fontFamily: "SFPro",
              fontWeight: "FontWeight.w400",
            },
          },
          hide_section: true,
          photos: [],
        },
      ],
    },
  ],
};

type Attendee = {
  firstName: string;
  lastName: string;
  email: string;
  wheelchair?: boolean;
  ageIssues?: boolean;
  impairments?: boolean;
  isExpanded?: boolean;
};

export const mourningCardApi = {
  async getMourningCard(funenralUuid: string): Promise<RemoteIviteModel> {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}public/funeral/${funenralUuid}/invite/`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return MourningCardConverter.fromBackend(response.data);
  },
  async confirmPresence(funenralUuid: string, attendess: Attendee[]): Promise<void> {
    const payload = attendess.map((attendee) => ({
      name: `${attendee.firstName} ${attendee.lastName}`,
      email: attendee.email,
      physical_limitations_assistance: attendee.wheelchair,
      age_issues: attendee.ageIssues,
      impairments: attendee.impairments,
    }))

    await axios.post(
      `${process.env.REACT_APP_BASE_API_URL}funeral/${funenralUuid}/attendee/`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  }
};
