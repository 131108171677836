import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import Translator from "../../components/translator";
import { GenericError } from "../../components/GenericError";

export const DeclineAttendancePage = () => {
  const location = useLocation();

  if (!location.state) {
    return <GenericError />;
  }

  const { liveStream, starName } = location.state as {
    liveStream: string, starName: string
  };

  return (
    <div className="max-h-screen min-h-screen bg-white flex justify-center items-center p-5">
      <div className="w-full min-h-full max-h-full max-w-lg">
        <div className="flex min-h-full max-h-full flex-col">
          <main
            className="flex flex-col items-center py-16"
          >
            <div
              className="w-16 h-16 bg-white text-error rounded-full flex items-center justify-center border border-error cursor-pointer"
            >
              <FontAwesomeIcon
                icon={["fal", "times"]}
                size="xl"
              />
            </div>

            <div className="py-14 flex flex-col items-center">
              <p className="text-xl font-500 text-darkGray2">
                <Translator path="attendees.yourAbsenceHasBeenRegistered" />
              </p>

              <p className="text-base text-mainGrey mt-4 font-400 text-center leading-6">
                {
                  liveStream ?
                    <Translator path="attendees.youCanAlsoAttendTheFuneralViaTheLivestream" />
                    : <Translator path="attendees.youCanDownloadTheAppAndShareYourMemoriesAbout" />
                }
              </p>
            </div>

            <DownloadApp starName={starName} />

            {
              liveStream &&
              <div
                className="flex items-center justify-center p-6 cursor-pointer"
                onClick={() => navigator.clipboard.writeText(liveStream)}
              >
                <p className="text-base font-500 text-primary">
                  <Translator path="attendees.copyLiveStreamLink" />
                </p>
              </div>
            }
          </main>
        </div>
      </div>
    </div>
  );
};

export const DownloadApp = ({ starName }: {
  starName: string
}) => {
  return (
    <div className="flex flex-row items-center justify-center p-6 cursor-pointer">
      <div className="flex-shrink-0">
        <div >
          <img
            src="/imgs/app_store_logo.svg"
            alt="phone"
            className="w-20 h-20 md:w-24 md:h-24"
          />

        </div>
      </div>

      <div className="ml-4">
        <p className="text-base text-primary font-400 w-[230] text-left">
          <Translator path="attendees.downloadTheAppAndShareYourMemoriesAbout" />
          <span className="font-500">{starName}'s.</span>
        </p>
      </div>
    </div>
  );
};

export default DownloadApp;