import React, { useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import {
  InputField,
  Switch,
  TextButton,
  OutlinedButton,
  FilledButton,
} from "../../components";
import Translator from "../../components/translator";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { mourningCardApi } from "../../../services/api/mourning-card/mourningCardApi";
import { GenericError } from "../../components/GenericError";

type Attendee = {
  firstName: string;
  lastName: string;
  email: string;
  needSpecialAssistance?: boolean;
  wheelchair?: boolean;
  ageIssues?: boolean;
  impairments?: boolean;
  isExpanded?: boolean;
};

export const AttendancePage = () => {
  const { funeralUuid } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const [attendees, setAttendees] = React.useState<Attendee[]>([
    {
      firstName: "",
      lastName: "",
      email: "",
      needSpecialAssistance: false,
      isExpanded: true,
    },
  ]);

  const [formValid, setFormValid] = React.useState(false);
  const validateForm = useCallback(() => {
    const isFormValid = attendees.every((attendee) => {
      return attendee.firstName && attendee.lastName && attendee.email;
    });

    setFormValid(isFormValid && attendees.length > 0);
  }, [attendees]);

  React.useEffect(() => {
    validateForm();
  }, [attendees, validateForm]);

  const handleInputChange = (
    index: number,
    field: "firstName" | "lastName" | "email",
    value: string
  ) => {
    const newAttendees = [...attendees];
    newAttendees[index][field] = value;

    setAttendees(newAttendees);
  };

  const toggleExpand = (index: number) => {
    const newAttendees = [...attendees];
    newAttendees[index].isExpanded = !newAttendees[index].isExpanded;
    setAttendees(newAttendees);
  };

  const addAttendee = () => {
    setAttendees([
      ...attendees,
      {
        firstName: "",
        lastName: "",
        email: "",
        isExpanded: true,
      },
    ]);
  };

  const removeAttendee = (index: number) => {
    const newAttendees = [...attendees];
    newAttendees.splice(index, 1);
    setAttendees(newAttendees);
  };

  const submitForm = async () => {
    await mourningCardApi
      .confirmPresence(funeralUuid!, attendees)
      .then(() => {
        navigate(`/invite/done`, {
          replace: true,
          state: {
            starName: starName,
            liveStream: liveStream
          }
        });
      }).catch(() => {
        alert("Error");
      });
  }

  if (!location.state) {
    return <GenericError />;
  }

  const { starName, liveStream } = location.state as {
    liveStream: string, starName: string
  };

  return (
    <div className="App font-sfPro">
      <div className="fixed top-0 left-0 w-full bg-white py-4 z-10">
        <h2 className="text-center font-600 text-darkGray2 text-base md:text-xl">
          <Translator path="attendees.confirmAttendees" />
        </h2>
      </div>

      <div className="min-h-screen bg-white flex justify-center px-5 pt-14 pb-24">
        <div className="w-full max-w-lg">
          <p className="text-left mt-14 mb-6 text-darkGray2 font-600 text-lg md:text-base">
            <Translator path="attendees.whoWillBeAttending" />
          </p>

          <div className="bg-lightGrey px-3 py-2 rounded-lg border border-primary">
            <p className="text-darkGray text-sm md:text-base">
              <Translator path="attendees.allAttendeesWillReceive" />
            </p>
          </div>

          <div className="mt-6 flex flex-col gap-y-4">
            {attendees.map((attendee, index) => (
              <React.Fragment key={index}>
                <div
                  className={`flex flex-row items-center justify-between cursor-pointer
                   lg:hover:bg-lightGold lg:hover:rounded-lg
                   p-2 transition-transform duration-200 border-b border-dashed
                   border-lightGold
                  `}
                  onClick={() => toggleExpand(index)}
                >
                  <div className="flex flex-row items-center">
                    <div className="flex items-center justify-center w-8 h-8 bg-white text-primary rounded-full mr-3 border border-primary">
                      {index + 1}
                    </div>

                    <p className="text-darkGray2 text-base md:text-lg font-500">
                      {attendee.firstName} {attendee.lastName}
                    </p>
                  </div>

                  <FontAwesomeIcon
                    icon={faChevronUp}
                    className={`text-primary transform transition-transform duration-200 ${attendee.isExpanded ? "rotate-180" : ""
                      }`}
                  />
                </div>

                {attendee.isExpanded && (
                  <>
                    <InputField
                      label="general.firstName"
                      onChange={(value) =>
                        handleInputChange(index, "firstName", value)
                      }
                      initialValue={attendee.firstName}
                      onValidate={(value) =>
                        value
                          ? { errorText: "" }
                          : { errorText: t("general.fieldRequired") }
                      }
                    />

                    <InputField
                      label="general.lastName"
                      onChange={(value) =>
                        handleInputChange(index, "lastName", value)
                      }
                      initialValue={attendee.lastName}
                      onValidate={(value) =>
                        value
                          ? { errorText: "" }
                          : { errorText: t("general.fieldRequired") }
                      }
                    />

                    <InputField
                      label="general.email"
                      onChange={(value) =>
                        handleInputChange(index, "email", value)
                      }
                      initialValue={attendee.email}
                      onValidate={(value) => {
                        if (!value) {
                          return { errorText: t("general.emailIsRequired") };
                        }

                        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailRegex.test(value)) {
                          return { errorText: t("general.invalidEmail") };
                        }

                        return { errorText: "" };
                      }}
                    />

                    <div className="mt-4">
                      <Switch
                        label="attendees.doesThisPersonRequireSpecialAssistance"
                        onChange={() => {
                          const newAttendees = [...attendees];
                          newAttendees[index].needSpecialAssistance =
                            !attendee.needSpecialAssistance;

                          newAttendees[index].impairments = false;
                          newAttendees[index].wheelchair = false;
                          newAttendees[index].ageIssues = false;

                          setAttendees(newAttendees);
                        }}
                        initialValue={attendee.needSpecialAssistance}
                      />

                      {attendee.needSpecialAssistance && (
                        <div className="mt-4 ml-4 flex flex-col gap-4">
                          <Switch
                            label="attendees.hearingAndOrVisualImpairments"
                            onChange={() => {
                              const newAttendees = [...attendees];
                              newAttendees[index].impairments =
                                !attendee.impairments;

                              setAttendees(newAttendees);
                            }}
                            initialValue={attendee.impairments}
                          />

                          <Switch
                            label="attendees.physicalLimitationsWheelchair"
                            onChange={() => {
                              const newAttendees = [...attendees];
                              newAttendees[index].wheelchair =
                                !attendee.wheelchair;

                              setAttendees(newAttendees);
                            }}
                            initialValue={attendee.wheelchair}
                          />

                          <Switch
                            label="attendees.ageIssues"
                            onChange={() => {
                              const newAttendees = [...attendees];
                              newAttendees[index].ageIssues =
                                !attendee.ageIssues;

                              setAttendees(newAttendees);
                            }}
                            initialValue={attendee.ageIssues}
                          />
                        </div>
                      )}
                    </div>

                    <div className="pt-4 flex flex-row justify-end">
                      <TextButton
                        text="general.remove"
                        onClick={() => removeAttendee(index)}
                      />
                    </div>
                  </>
                )}
              </React.Fragment>
            ))}
          </div>

          <div className="relative my-6">
            <div className="border-t border-dashed border-lightGold w-full absolute top-1/2 transform -translate-y-1/2"></div>

            <div className="relative z-10 flex justify-center">
              <OutlinedButton
                text="general.new"
                onClick={() => addAttendee()}
                icon={<FontAwesomeIcon icon={faPlus} />}
              />
            </div>
          </div>

          <div className="mt-auto w-full bg-white">
            <div className="max-w-lg mx-auto flex justify-center">
              <FilledButton
                label="general.confirm"
                onClick={submitForm}
                disabled={!formValid}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
