export const NLTranslations = {
  common: {
    attendees: {
      confirmAttendees: 'Bevestig deelnemers',
      whoWillBeAttending: 'Wie zal de begrafenis met u bijwonen?',
      allAttendeesWillReceive: 'Alle deelnemers ontvangen een bevestiging en informatie over de begrafenis via e-mail.',
      doesThisPersonRequireSpecialAssistance: 'Heeft deze persoon speciale hulp nodig?',
      hearingAndOrVisualImpairments: 'Gehoor- en/of visuele beperkingen',
      physicalLimitationsWheelchair: 'Fysieke beperkingen (rolstoel)',
      ageIssues: 'Leeftijdsgerelateerde problemen',
      inLovingMemoryOf: 'In liefdevolle herinnering aan',
      ourFamilyWouldLikeToKnow: 'Onze familie zou graag willen weten of u de begrafenis zult bijwonen:',
      present: 'Aanwezig',
      absent: 'Afwezig',
      yourAbsenceHasBeenRegistered: 'Uw afwezigheid is geregistreerd',
      yourPresenceHasBeenRegistered: 'Uw aanwezigheid is geregistreerd',
      youCanAlsoAttendTheFuneralViaTheLivestream: 'U kunt de begrafenis ook bijwonen via de Livestream. U ontvangt een melding een uur voor aanvang; houd de app in de gaten.',
      copyLiveStreamLink: 'Kopieer Livestream-link',
      downloadTheAppAndShareYourMemoriesAbout: 'Download de app en deel je herinneringen over ',
      youCanDownloadTheAppAndShareYourMemoriesAbout: 'Download de app en deel je herinneringen over ',
      youCanCheckAllTheDetailForTheFuneralInTheFuneralPage: 'U kunt alle details voor de begrafenis controleren op de begrafenispagina.',
    },
    general: {
      firstName: 'Voornaam',
      lastName: 'Achternaam',
      email: 'E-mail',
      fieldRequired: 'Dit veld is verplicht',
      emailIsRequired: 'E-mail is verplicht',
      invalidEmail: 'Ongeldige e-mail',
      remove: 'Verwijderen',
      new: 'Nieuw',
      confirm: 'Bevestigen',
      done: 'Klaar',
      oops: 'Oeps!',
      somethingWentWrong: 'Er is iets misgegaan. Controleer de link en probeer het opnieuw.',
    },
    privacy: {
      error: 'Er is een fout opgetreden bij het laden van het privacybeleid.',
      termsAndConditions: 'Algemene voorwaarden',
      lastUpdated: 'Laatst bijgewerkt:',
      pleaseReadTheTermsAndConditionsBelow: 'Lees de algemene voorwaarden hieronder lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    }
  },
}
