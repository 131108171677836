import { useTranslation } from "react-i18next";
import { useLoadPrivacy } from "../../../services/api/privacyApi";
import { useEffect } from "react";
import Translator from "../../components/translator";

export const PrivacyPage = () => {
  const { i18n } = useTranslation();

  const { privacy, loadPrivacy, loadPrivacyError, loadPrivacyLoading } = useLoadPrivacy(i18n.language);

  useEffect(() => {
    loadPrivacy();
  }, [loadPrivacy]);

  return (
    <div className="max-h-screen min-h-screen bg-white flex justify-center relative p-5">
      <div className="w-full min-h-full max-h-full max-w-lg relative">
        <div className="flex min-h-full max-h-full flex-col relative">
          {
            (loadPrivacyLoading || privacy == null) && (
              <div className="fixed inset-0 flex items-center justify-center">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-primary border-opacity-75"></div>
              </div>
            )
          }

          {
            loadPrivacyError != null && (
              <div className="text-red-500">
                <Translator path="privacy.error" />
              </div>
            )
          }

          {
            privacy != null && (
              <>
                <div className="flex flex-col gap-y-4">
                  <p className="text-darkGray md:text-2xl text-xl font-600">
                    <Translator path="privacy.termsAndConditions" />
                  </p>

                  <p className="text-mainGrey md:text-lg text-sm font-400">
                    <Translator path="privacy.lastUpdated" /> {privacy.dateTime.toLocaleDateString(i18n.language, {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </p>

                  <p className="text-mainGrey md:text-lg text-sm font-400">
                    <Translator path="privacy.pleaseReadTheTermsAndConditionsBelow" />
                  </p>
                </div>

                <div
                  dangerouslySetInnerHTML={{ __html: privacy.content }}
                  className="mt-5"
                />
              </>
            )
          }
        </div>
      </div>
    </div>
  );
}