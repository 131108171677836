import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { enUsTranslations } from './locales/en-us'
import { NLTranslations } from './locales/nl-NL'

const i18nConfig = {
  resources: {
    'en-US': enUsTranslations,
    'nl-NL': NLTranslations,
  },
  fallbackLng: {
    'en-US': ['en-US'],
    default: ['en-US'],
  },
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
  },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nConfig)

export default i18n
