import {
  PropsWithChildren,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useFetchMourningCard } from "../../../hooks/useFetchMourningCard";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  IFuneral,
  IMourningCard,
  IMourningCardPageSectionModel,
  IMourningCardPageSectionPhotoModel,
  IStar,
} from "../../../services/api/mourning-card/interfaces";
import {
  getImageStyleProps,
  getStylePropsFromTextStyle,
  getTailwindClassesFromTextStyle,
} from "../../helpers/mourningCard";
import { library } from "@fortawesome/fontawesome-svg-core";
import { all } from "@awesome.me/kit-4824818eb6/icons";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Translator from "../../components/translator";
import i18n from "../../../main/i18n/i18n";

library.add(...all);

export const MourningCardPage = () => {
  const { funeralUuid } = useParams();

  const {
    fetchMourningCard,
    mourningCardLoading,
    mourningCard,
    star,
    funeral,
  } = useFetchMourningCard(funeralUuid as string);

  const [currentPage, setCurrentPage] = useState(0);
  const [containerHeight, setContainerHeight] = useState(1080);

  const sliderRef = useRef<Slider>(null);
  const sliderContainerRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    let resizeTimeout: NodeJS.Timeout;

    const onResizeListener = () => {
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }

      resizeTimeout = setTimeout(() => {
        if (sliderContainerRef.current) {
          setContainerHeight(sliderContainerRef.current.clientHeight);
        }
      }, 200);
    };

    onResizeListener();

    window.addEventListener("resize", onResizeListener);

    return () => {
      window.removeEventListener("resize", onResizeListener);
      if (resizeTimeout) {
        clearTimeout(resizeTimeout);
      }
    };
  }, [mourningCardLoading]);

  useEffect(() => {
    fetchMourningCard();
  }, [fetchMourningCard]);

  return (
    <div className="max-h-screen min-h-screen bg-white flex justify-center relative">
      <div className="w-full min-h-full max-h-full max-w-lg relative">
        <div className="flex min-h-full max-h-full flex-col relative">
          {mourningCardLoading || !mourningCard ? (
            <MourningCardLoadingState />
          ) : (
            <>
              <main
                className="flex-1 relative min-h-full max-h-full h-full "
                ref={sliderContainerRef}
              >
                {currentPage === 3 ? (
                  <>
                    <div
                      className="w-full absolute top-0 left-0 right-0 bottom-0 bg-cover bg-center blur-2xl bg-white"
                      style={{
                        height: containerHeight,
                      }}
                    />
                  </>
                ) : (
                  <MourningCardBackground
                    data={mourningCard}
                    height={containerHeight}
                  />
                )}

                <Slider
                  ref={sliderRef}
                  fade
                  speed={1000 * 1}
                  arrows={false}
                  infinite={false}
                  afterChange={(currentSlide) => {
                    setCurrentPage(currentSlide);
                  }}
                >
                  <MourningCardSliderPageWrapper height={containerHeight}>
                    <MourningCardPageOne data={mourningCard} />
                  </MourningCardSliderPageWrapper>
                  <MourningCardSliderPageWrapper height={containerHeight}>
                    <MourningCardPageTwo data={mourningCard} />
                  </MourningCardSliderPageWrapper>
                  <MourningCardSliderPageWrapper height={containerHeight}>
                    <MourningCardPageThree data={mourningCard} />
                  </MourningCardSliderPageWrapper>
                  <MourningCardSliderPageWrapper height={containerHeight}>
                    <MourningCardPageFour funeral={funeral!} star={star!} />
                  </MourningCardSliderPageWrapper>
                </Slider>

                <footer className="absolute bottom-0 left-0 right-0 py-9 px-7">
                  <div className="flex">
                    {currentPage === 0 ? (
                      <div className="w-10 h-10" />
                    ) : (
                      <button
                        onClick={() => sliderRef.current?.slickPrev()}
                        className="bg-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-gray"
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="text-primary"
                        />
                      </button>
                    )}
                    <SliderFooterDotIndicator
                      pageCount={4}
                      currentPage={currentPage}
                    />

                    {currentPage === 3 ? (
                      <div className="w-10 h-10" />
                    ) : (
                      <button
                        onClick={() => sliderRef.current?.slickNext()}
                        className="bg-white rounded-full w-10 h-10 flex items-center justify-center hover:bg-gray"
                      >
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="text-primary"
                        />
                      </button>
                    )}
                  </div>
                </footer>
              </main>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const MourningCardBackground = ({
  data,
  height,
}: {
  data: IMourningCard;
  height: number;
}) => {
  return (
    <>
      <div
        className="w-full absolute top-0 left-0 right-0 bottom-0 bg-cover bg-center blur-2xl"
        style={{
          backgroundColor: data.backgroundColor,
          backgroundImage: `url(${data.backgroundImage})`,
          height,
        }}
      />
      <div
        className="w-full absolute top-0 left-0 right-0 bottom-0 bg-cover bg-center"
        style={{
          backgroundColor: data.backgroundColor,
          backgroundImage: `url(${data.backgroundImage})`,
          height,
        }}
      />
    </>
  );
};

export const SliderFooterDotIndicator = ({
  pageCount,
  currentPage,
}: {
  pageCount: number;
  currentPage: number;
}) => {
  return (
    <div className="flex-1 flex items-center justify-center">
      {Array.from({ length: pageCount }).map((_, index) => (
        <div
          key={index}
          className={`${
            currentPage === index ? "bg-primary" : "bg-textDisabled"
          } w-2 h-2 rounded-full m-1`}
        />
      ))}
    </div>
  );
};

export const MourningCardSliderPageWrapper = ({
  height,
  children,
}: PropsWithChildren<{ height: number }>) => {
  return (
    <div
      className="h-full"
      style={{
        height,
      }}
    >
      {children}
    </div>
  );
};

export const MourningCardSliderPageInnerWrapper = ({
  children,
}: PropsWithChildren<{}>) => {
  return (
    <div className="h-full py-20 overflow-y-auto px-7 flex flex-col justify-evenly">
      {children}
    </div>
  );
};

export const MourningCardSectionImageDisplayItem = ({
  data,
  i,
  numberOfPhotos,
}: {
  data: IMourningCardPageSectionPhotoModel;
  i: number;
  numberOfPhotos: number;
}) => {
  const styleProps = getImageStyleProps(data);

  let thisItemRotation = "0deg";

  let thisItemTranslate = "0px";

  if (numberOfPhotos === 1) {
    thisItemRotation = "3.18deg";
  } else if (i === 0) {
    thisItemRotation = "-3.18deg";
  } else {
    thisItemRotation = "3.18deg";
  }

  if (numberOfPhotos === 1) {
    thisItemTranslate = "0 -10px";
  } else if (i === 0) {
    thisItemTranslate = "10px -10px";
  } else {
    thisItemTranslate = "-10px 10px";
  }

  return (
    <div className="w-full flex items-center justify-center">
      <div
        className="w-full bg-cover bg-center"
        style={{
          ...styleProps,
          backgroundImage: `url(${data.mourningCardImage})`,
          rotate: styleProps.rotation === "tilted" ? thisItemRotation : "0deg",
          translate:
            styleProps.rotation === "tilted" ? `${thisItemTranslate}` : "0px",
        }}
      />
    </div>
  );
};

export const MourningCardSectionImageDisplay = ({
  data,
}: {
  data: IMourningCardPageSectionModel;
}) => {
  return data.hideSection ? (
    <></>
  ) : (
    <div className="w-full flex items-center justify-center gap-4 p-6">
      {data.photos.map((photo, index) => (
        <MourningCardSectionImageDisplayItem
          key={index}
          data={photo}
          i={index}
          numberOfPhotos={data.photos.length}
        />
      ))}
    </div>
  );
};

export const MourningCardAvatarImageDisplay = ({
  data,
}: {
  data: IMourningCard;
}) => {
  const avatarImage = data.avatar;
  return data.hideAvatar ? (
    <></>
  ) : (
    <div className="w-full flex items-center justify-center">
      <div
        className="w-[180px] h-[180px] rounded-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${avatarImage})`,
        }}
      />
    </div>
  );
};

export const MourningCardTextDisplay = ({
  data,
}: {
  data: IMourningCardPageSectionModel;
}) => {
  const textClasses = getTailwindClassesFromTextStyle(data.textStyle);
  const styleProps = getStylePropsFromTextStyle(data.textStyle);
  return data.hideSection ? (
    <></>
  ) : (
    <div className="px-20 py-5">
      <p className={textClasses} style={styleProps}>
        {data.content.split("\n").map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </p>
    </div>
  );
};

export const MourningCardDatesDisplay = ({ data }: { data: IMourningCard }) => {
  const textClasses = getTailwindClassesFromTextStyle(data.dates.textStyle);
  const styleProps = getStylePropsFromTextStyle(data.dates.textStyle);

  const { i18n } = useTranslation();
  return (
    <div className="flex gap-x-4 w-full align-middle justify-center">
      <p className={`${textClasses} flex items-center`} style={styleProps}>
        <FontAwesomeIcon
          icon={["fal", "sparkle"]}
          color={data.dates.textStyle.iconColor ?? undefined}
          className="mr-4"
        />
        {data.dates.birthDate.toLocaleDateString(i18n.language, {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
      </p>
      <p className={`${textClasses} flex items-center`} style={styleProps}>
        <FontAwesomeIcon
          icon={["fal", "cross"]}
          color={data.dates.textStyle.iconColor ?? undefined}
          className="mr-4"
        />
        {data.dates.deathDate.toLocaleDateString(i18n.language, {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
      </p>
    </div>
  );
};

export const MourningCardPageOne = ({ data }: { data: IMourningCard }) => {
  return (
    <MourningCardSliderPageInnerWrapper>
      <MourningCardAvatarImageDisplay data={data} />
      <MourningCardTextDisplay data={data.pages[0].sections[0]} />
      <MourningCardDatesDisplay data={data} />
      <MourningCardTextDisplay data={data.pages[0].sections[1]} />
    </MourningCardSliderPageInnerWrapper>
  );
};

export const MourningCardPageTwo = ({ data }: { data: IMourningCard }) => {
  return (
    <MourningCardSliderPageInnerWrapper>
      <MourningCardTextDisplay data={data.pages[1].sections[0]} />
      <MourningCardSectionImageDisplay data={data.pages[1].sections[1]} />
      <MourningCardTextDisplay data={data.pages[1].sections[2]} />
      <MourningCardTextDisplay data={data.pages[1].sections[3]} />
      <MourningCardTextDisplay data={data.pages[1].sections[4]} />
    </MourningCardSliderPageInnerWrapper>
  );
};

export const MourningCardPageThree = ({ data }: { data: IMourningCard }) => {
  console.log(data.pages[2].sections[2]);
  return (
    <MourningCardSliderPageInnerWrapper>
      <MourningCardTextDisplay data={data.pages[2].sections[0]} />
      <MourningCardSectionImageDisplay data={data.pages[2].sections[1]} />
      <MourningCardTextDisplay data={data.pages[2].sections[2]} />
      <MourningCardTextDisplay data={data.pages[2].sections[3]} />
      <MourningCardTextDisplay data={data.pages[2].sections[4]} />
    </MourningCardSliderPageInnerWrapper>
  );
};

export const MourningCardPageFour = ({
  star,
  funeral,
}: {
  star: IStar;
  funeral: IFuneral;
}) => {
  return (
    <div className="h-full py-20 overflow-y-auto px-7 flex flex-col items-center">
      <div className="w-full flex items-center justify-center">
        <div
          className="w-[180px] h-[180px] rounded-full bg-cover bg-center"
          style={{
            backgroundImage: `url(${star.photo})`,
          }}
        />
      </div>

      <p className="py-6 text-mainGrey font-700 ">
        <Translator path="attendees.inLovingMemoryOf" />
      </p>

      <div className="w-full flex items-center justify-center py-2">
        <p className="text-3xl font-500 text-center text-black">
          {star.firstName} {star.lastName}
        </p>
      </div>

      <div className="flex gap-x-4 w-full align-middle justify-center">
        <p className="text-sm text-mainGrey font-400">
          <FontAwesomeIcon
            icon={["fal", "sparkle"]}
            color={"#8C7956"}
            className="mr-4"
          />
          {star.dateOfBirth.toLocaleDateString(i18n.language, {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </p>
        <p className="text-sm text-mainGrey font-400">
          <FontAwesomeIcon
            icon={["fal", "cross"]}
            color={"#8C7956"}
            className="mr-4"
          />
          {star.dateOfDeath.toLocaleDateString(i18n.language, {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </p>
      </div>

      <div className="w-full flex items-center justify-center py-14">
        <p className="text-xl font-600 text-center text-mainGrey">
          <Translator path="attendees.ourFamilyWouldLikeToKnow" />
        </p>
      </div>

      <div className="w-full flex items-center justify-center gap-4 py-11">
        <ConfirmationButton funeral={funeral} star={star} />
      </div>
    </div>
  );
};

export const ConfirmationButton = ({
  funeral,
  star,
}: {
  star: IStar;
  funeral: IFuneral;
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex items-center justify-center space-x-10">
        <div className="flex flex-col items-center">
          <div
            className="w-16 h-16 bg-white text-success rounded-full flex items-center justify-center border border-success cursor-pointer"
            onClick={() => {
              navigate(`/${funeral.uuid}/attendance`, {
                replace: true,
                state: {
                  starName: `${star.firstName}`,
                  liveStream: funeral.liveStream,
                },
              });
            }}
          >
            <FontAwesomeIcon icon={["fal", "check"]} size="xl" />
          </div>

          <p className="mt-4 text-base font-700 text-darkGray2">
            <Translator path="attendees.present" />
          </p>
        </div>

        <div className="flex flex-col items-center">
          <div
            className="w-16 h-16 bg-white text-error rounded-full flex items-center justify-center border border-error cursor-pointer"
            onClick={() => {
              navigate("/invite/decline", {
                replace: true,
                state: {
                  liveStream: funeral.liveStream,
                  starName: `${star.firstName}`,
                },
              });
            }}
          >
            <FontAwesomeIcon icon={["fal", "times"]} size="xl" />
          </div>

          <p className="mt-4 text-base font-700 text-darkGray2">
            <Translator path="attendees.absent" />
          </p>
        </div>
      </div>
    </>
  );
};

export const MourningCardLoadingState = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-primary border-opacity-75"></div>
    </div>
  );
};
