import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Translator from "./translator"

export const GenericError = () => {
  return (
    <div className="max-h-screen min-h-screen bg-white flex justify-center items-center p-5">
      <div className="w-full min-h-full max-h-full max-w-lg">
        <div className="flex min-h-full max-h-full flex-col">
          <main
            className="flex flex-col items-center py-16"
          >
            <div
              className="w-16 h-16 bg-white text-error rounded-full flex items-center justify-center border border-error cursor-pointer"
            >
              <FontAwesomeIcon
                icon={["fal", "times"]}
                size="xl"
              />
            </div>

            <div className="py-14 flex flex-col items-center">
              <p className="text-xl font-500 text-darkGray2">
                <Translator path="general.oops" />
              </p>

              <p className="text-base text-mainGrey mt-4 font-400 text-center leading-6">
                <Translator path="general.somethingWentWrong" />
              </p>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
