import { FC } from "react";
import { useTranslation } from "react-i18next";

type OutlinedButtonProps = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  icon?: React.ReactNode;
};

export const OutlinedButton: FC<OutlinedButtonProps> = ({
  text,
  onClick,
  disabled = false,
  icon,
}) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={!disabled ? onClick : undefined}
      disabled={disabled}
      className={`
          text-sm text-primary font-600 border border-mainGrey rounded-full
          bg-white focus:outline-none
          lg:hover:bg-lightGold lg:hover:border-primary
          active:bg-error active:border-primary
          px-4 py-2 
          ${disabled ? 'text-primary border-lightGrey' : 'text-primary border-primary'}
        `}
    >
      {icon && <span className="mr-2">{icon}</span>}
      {t(text)}
    </button>
  );
};
