import { FC, useEffect, useState, useId } from 'react';
import { useTranslation } from 'react-i18next';


interface InputFieldProps {
  label: string;
  initialValue?: string;
  type?: string;
  inputFormatters?: (value: string) => string;
  onChange?: (value: string) => void;
  errorText?: string;
  maxLength?: number;
  disabled?: boolean;
  onValidate?: (value: string) => { errorText: string };
}

export const InputField: FC<InputFieldProps> = ({
  label,
  initialValue,
  type = 'text',
  inputFormatters = (value) => value,
  onChange,
  maxLength,
  disabled = false,
  onValidate = (value) => ({ errorText: '' }),
}) => {
  const [value, setValue] = useState(initialValue || '');
  const inputId = useId();
  const [error, setError] = useState<string | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = inputFormatters(e.target.value);
    setValue(formattedValue);
    onChange && onChange(formattedValue);

    const { errorText: validationError } = onValidate(formattedValue);
    setError(validationError);
  };


  return (
    <div className="flex flex-col">
      {
        value &&
        <label
          htmlFor={inputId}
          className={`text-sm text-darkGray2 mb-2 ${error ? 'text-error' : 'text-darkGray'}`}>
          {t(label)}
        </label>
      }

      <div className={`flex items-center ${disabled ? 'border border-lightGrey' : ''} rounded-md`}>
        <input
          id={inputId}
          type={type}
          value={value}
          placeholder={t(label)}
          onChange={handleChange}
          maxLength={maxLength}
          disabled={disabled}
          className={`
            flex-1 p-2 rounded-md 
            ${error ? 'border-2 border-error' : 'border border-darkGray'}
            ${disabled || !value ? 'bg-lightGrey' : 'bg-transparent'}
            ${!error && 'focus:border-primary'}
            placeholder-mainGrey focus-visible:outline-none
          `}
          onFocus={() => {
            if (disabled) {
              setValue(value.trim());
            }
          }}
        />
      </div>

      {error &&
        <span className="mt-1 ml-3 text-error text-sm font-400">{error}</span>
      }
    </div>
  );
};
