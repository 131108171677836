
import { useTranslation } from 'react-i18next'

export const Translator = ({ path }: { path: string }): JSX.Element => {
  const { t } = useTranslation();

  return <>{t(path)} </>;
};

export default Translator
