export type IMourningCardTextStyleAligment =
  | "TextAlign.center"
  | "TextAlign.end"
  | "TextAlign.justify"
  | "TextAlign.left"
  | "TextAlign.right"
  | "TextAlign.start";

export type IMourningCardTextStyleFontWeight =
  | "FontWeight.w100"
  | "FontWeight.w200"
  | "FontWeight.w300"
  | "FontWeight.w400"
  | "FontWeight.w500"
  | "FontWeight.w600"
  | "FontWeight.w700"
  | "FontWeight.w800"
  | "FontWeight.w900";

export interface IMourningCardTextStyleTextStyle {
  color: string;
  fontSize: number;
  fontWeight: IMourningCardTextStyleFontWeight;
  fontFamily: string;
  height: number;
}

export interface IMourningCardTextStyle {
  textStyle: IMourningCardTextStyleTextStyle;
  alignment: IMourningCardTextStyleAligment;
  iconColor: string | null;
}

export interface IMourningCardDates {
  order: number;
  birthDate: Date;
  deathDate: Date;
  textStyle: IMourningCardTextStyle;
  hideSection: boolean;
}
export enum MourningCardPageSectionTypeEnum {
  text = "text",
  image = "image",
}

export interface IMourningCardPageSectionPhotoStyleModel {
  border: boolean;
  orientation: MourningCardPageSectionPhotoStyleOrientationEnum;
  rotation: MourningCardPageSectionPhotoStyleRotationEnum;
}

export enum MourningCardPageSectionPhotoStyleRotationEnum {
  tilted = "tilted",
  straight = "straight",
}

export enum MourningCardPageSectionPhotoStyleOrientationEnum {
  landscape = "landscape",
  portrait = "portrait",
}

export interface IMourningCardPageSectionPhotoModel {
  mourningCardImage: string;
  imageStyle: IMourningCardPageSectionPhotoStyleModel;
}

export interface IMourningCardPageSectionModel {
  uuid: string | null;
  order: number;
  type: MourningCardPageSectionTypeEnum;
  mourningCard: string | null;
  content: string;
  textStyle: IMourningCardTextStyle;
  hideSection: boolean;
  photos: IMourningCardPageSectionPhotoModel[];
}

export interface IMourningCardPageModel {
  pageNumber: number;
  sections: IMourningCardPageSectionModel[];
}

export interface IMourningCard {
  uuid: string | null;
  dates: IMourningCardDates;
  avatar: string | null;
  hideAvatar: boolean;
  backgroundImage: string | null;
  backgroundColor: string;
  pages: IMourningCardPageModel[];
}

export interface IFuneral {
  uuid: string;
  isPublic: boolean;
  type: string;
  liveStream: string | null;
}

export interface IStar {
  uuid: string;
  firstName: string;
  lastName: string;
  photo: string;
  dateOfBirth: Date;
  dateOfDeath: Date;
}

export type RemoteIviteModel = {
  mourninCard: IMourningCard;
  funeral: IFuneral;
  star: IStar;
};
